import { render, staticRenderFns } from "./LessonBanksBottomToolbar.vue?vue&type=template&id=1b83065a&"
import script from "./LessonBanksBottomToolbar.vue?vue&type=script&lang=ts&"
export * from "./LessonBanksBottomToolbar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCol,VIcon,VList,VListItem,VMenu,VRow,VToolbar})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b83065a')) {
      api.createRecord('1b83065a', component.options)
    } else {
      api.reload('1b83065a', component.options)
    }
    module.hot.accept("./LessonBanksBottomToolbar.vue?vue&type=template&id=1b83065a&", function () {
      api.rerender('1b83065a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/lessonlists/LessonBanksBottomToolbar.vue"
export default component.exports