var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isEmbedded
    ? _c(
        "v-toolbar",
        [
          _c(
            "v-row",
            {
              staticClass: "text-center justify-space-between",
              attrs: { elevation: "1", outlined: "" },
            },
            [
              _c("pb-user-mode-selector"),
              _vm.isThereSelectedBanks
                ? _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "auto" } },
                    [
                      _c(
                        "pb-btn",
                        {
                          attrs: {
                            color: "primary",
                            label: _vm.$t("addLessonLabel"),
                            icon: "",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.addLesson.apply(null, arguments)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fal fa-plus")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isThereSelectedBanks
                ? _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            bottom: "",
                            "offset-y": "",
                            "close-on-click": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on: onMenu }) {
                                  return [
                                    _c(
                                      "pb-btn",
                                      _vm._g(
                                        {
                                          staticClass: "ml-2 my-1",
                                          attrs: {
                                            color: "primary",
                                            label: _vm.$t("actionsLabel"),
                                            icon: "",
                                          },
                                        },
                                        { ...onMenu }
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            "fal fa-ellipsis-stroke-vertical"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2041412116
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.importCSV()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-1",
                                      attrs: {
                                        color: "primary",
                                        elevation: "0",
                                        text: "",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "ml-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("importCSVLabel"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }