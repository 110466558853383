
import { Component } from 'vue-property-decorator';
import LessonBanksToolbar from './LessonBanksToolbar.vue';
@Component({
  components: {
    LessonBanksToolbar
  }
})
export default class LessonBanksBottomToolbar extends LessonBanksToolbar {
}
